// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/banner-login-bja.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-img[data-v-68be8905]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat!important;background-size:cover!important;background-position:0 -500px}.custom-input[data-v-68be8905]{border-radius:15px!important;padding:10px 15px!important;padding-left:45px!important;height:58px!important;text-transform:none!important}.custom-input[data-v-68be8905]::-moz-placeholder{text-transform:none!important;font-weight:300!important;color:#c3c3c3!important;font-size:14px!important}.custom-input[data-v-68be8905]::placeholder{text-transform:none!important;font-weight:300!important;color:#c3c3c3!important;font-size:14px!important}.img-centered[data-v-68be8905]{display:block;margin-left:auto;margin-right:auto}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
