const basicAreaChart = {
  sline: {
    chart: {
      id: "vuechart-example",
    },

chart: {
type: 'bar',
height: 300,
stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
            enabled: true
        }
},

colors: ['#28C76F', '#EA5455'],
dataLabels: {
    enabled: false
},
responsive: [{
    breakpoint: 280,
    options: {
        legend: {
        position: '',
        offsetY: 40,
        }
    }
    }],
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '20%',
            endingShape: 'rounded'
        },
        },
xaxis: {
    categories: [' Jan ', 'feb', 'march', 'april',
    'may', 'june' , 'july' , 'auguest'],
},
fill: {
    opacity: 1

},
  },
  series: [{
    name: 'Sales',
    data: [50, 45, 60, 70, 50, 45, 60, 70 ],
    }, {
    name: 'Purchase',
    data: [-21, -54, -45, -35, -21, -54, -45, -35]
    }],
};

const linechart = {
  morrisbar3 : {
      chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false,
          },
          stacked: true,
          gridTextSize: 11,
          hideHover: 'auto',
          resize: true
      },
       colors: ['#664dc9','#44c4fa'],
      dataLabels: {
          enabled: false
      },
     
          axis: {
              y: {
                  type: 'category',
                  // name of each category
                  categories: ['2006', '2007', '2008', '2009', '2010', '2011' ,'2012']
              },
          },
      },
      series: [{
          data: [12, 18, 15, 25, 30, 18, 12]
      }, {
          data: [18, 22, 18, 28, 35, 28, 18]
      }],
};

const pieflotChart = {
  flotpie1 : {
      chart: {
          height: 300,
          type: 'pie',
          toolbar: {
            show: false,
          }
      },
      colors: ['#664dc9', '#44c4fa', '#38cb89', '#ef4b4b',"#ffab00"],
      responsive: [{
          breakpoint: 480,
          options: {
              chart: {
                  width: 200
              }
          }
      }]
      },
      series: [10, 50, 30,30,60],  
};

export {
    basicAreaChart,
    linechart,
    pieflotChart
  };
