<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <div class="menu-tab">
          <div class="d-flex justify-content-start align-items-center gap-4 order-2 order-md-1 mt-4 mt-md-0">
              <div class="font-weight-normal text-md position-relative cursor-pointer px-4 py-2" @click="activePage('umum');"
                  :class="{ 'menu-top': active_page === 'umum', 'text-muted': active_page !== 'umum' }">
                  UMUM
              </div>
              
              <div class="font-weight-normal text-md position-relative cursor-pointer px-4 py-2" @click="activePage('rincian_produk');"
                  :class="{ 'menu-top': active_page === 'rincian_produk', 'text-muted': active_page !== 'rincian_produk' }">
                  RINCIAN PRODUK
              </div>
              
              
          </div>

        </div>

        <!-- /add -->
        <div class="card">
          <div class="card-body">
              
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="update">
              <div class="row" :class="{ 'overflow-auto max-h-64': active_page !== 'umum' }">
                <div class="col-lg-12 mb-3" v-show="active_page == 'umum'">
                  <div class="row mb-3">
                    <div class="col-md-6">

                      <div class="d-flex align-items-center">
                        <label for="" class="col-4">Tanggal</label>
                        <input type="date" class="form-control" placeholder="" disabled>
                      </div>
                    </div>
                    <div class="col-md-6">

                      <div class="d-flex align-items-center">
                        <label for="" class="col-3" >Foto Produk</label>
                        <input type="file" class="form-control" placeholder="" >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">

                    <div class="d-flex align-items-center">
                      <label for="" class="col-4">Nomor Permintaan</label>
                      <input type="text" class="form-control" placeholder="" disabled>
                    </div>
                  </div>
                  
                  <div class="col-md-6 mb-3">

                    <div class="d-flex align-items-center">
                      <label for="" class="col-4">Departemen</label>
                      <input type="text" class="form-control" placeholder="" > <!-- ganti jadi select form-->
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">

                    <div class="d-flex align-items-center">
                      <label for="" class="col-4">Status</label>
                      <input type="text" class="form-control" placeholder="" > <!-- ganti jadi select form-->
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">

                    <div class="d-flex align-items-center">
                      <label for="" class="col-4">Catatan</label>
                      <textarea class="form-control" placeholder=""></textarea>
                    </div>
                  </div>
                  
                </div>
              </div>


              <div class="row" :class="{ 'overflow-auto max-h-64': active_page !== 'rincian_produk' }">
                <div class="col-lg-12 mb-3" v-show="active_page == 'rincian_produk'">
                  <table class="table">
                    <thead style="background-color: #D6B884;">
                      <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col " class="border-1 border-end text-center text-white">Nama Produk</th>
                        <th scope="col " class="border-1 border-end text-center text-white">SKU</th>
                        <th scope="col " class="border-1 border-end text-center text-white">Qty</th>
                        <th scope="col" class="border-1 border-end text-center text-white">Satuan</th>
                        <th scope="col" class="border-1 border-end text-center text-white">Tanggal Diminta</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.nama_produk }}</td>
                          <td>{{ item.sku }}</td>
                          <td>{{ item.qty }}</td>
                          <td>{{ item.satuan }}</td>
                          <td>{{ item.tanggal }}</td>
                          
                      </tr>
                      
                    </tbody>
                  </table>
                  
                </div>
              </div>


              
              
              <div class="simpan-button d-flex justify-content-end">
                <button type="button" class="btn btn-primary me-2">Simpan</button>
                <button type="button" class="btn btn-outline-danger">Batal</button>
              </div>
            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>

  <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#modaladditempurchase"
    ref="buttonopenmodaladditempurchase" hidden><i class="fa fa-plus me-2"></i></a>
  <additempurchasemodal :marketingdata="data_marketing" :kategoridata="data_kategori" :itemdata="data_item_filter"
    :warehousedata="data_warehouse" @id_kategori_selected="handleKategoriSelected"
    @id_item_selected="handleItemSelected" :isMarketing="isMarketing" @item_object="handleItemAdd"
    :itemEdited="itemEdited">
  </additempurchasemodal>


</template>
<style scoped>
.order-total {

  color: #637381;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-right: 1px solid #f8f8f8;
  background: #fafbfe;
}

.order-amount {
  color: #212b36;
  font-size: 14px;
  padding: 10px;
  font-weight: 700;
  text-align: right;

}

.menu-top {
  background-color: rgba(214, 184, 132, 0.3); /* Adjust opacity and color as needed */
  border-left: 4px solid #D6B884; /* Primary color (Bootstrap default) */
  color: black; /* Dark text color (Bootstrap default) */
  font-weight: 600;
  border-radius: 0.125rem;
}
</style>
  
<script>
  import Cookies from "js-cookie";
  import axios from "axios";
  import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiah, rupiahInput } from "@/assets/js/function";
  // import modaladditem from "./modaladditem";
  import { ModelSelect } from 'vue-search-select';
  
  // import { Form, Field } from "vee-validate";
  // import * as Yup from "yup";
  
  export default {
    components: {
      ModelSelect
    },
    name: "edit Invoice",
    data() {
      return {
        filter: true,
        title: "Tambah Uang Muka Penjualan",
        title1: "Tambah data uang muka penjualan",
        config: "",
        api: "",
        foto_ktp_img: "",
        googleMap: "",
  
        //data
        currentDate: new Date,
        currentData: {
          suplier_detail: {
            id: "",
          },
          invoice_detail: [],
          invoice_payment: [],
          discount_type: "rupiah",
          calculated_discount: "0",
          discount_amount: 0,
          tax_amount: 0,
          tax_rate: 0,
          tax_name: "PPN",
          tax_type: "persen",
          grand_total: 0,
          bank_name: "",
          po_number: "",
          code_project: "",
          quotation_note: "",
          quotation_term: "",
  
        },
  
        data_suplier: [],
        data_item: [],
        data_kategori: [],
        data_marketing: [],
        data_payments: [],
        data_bank: [],
        // data_list_items_in_warehouse: [],
        data_warehouse: [],
        data_ekspedisi: [],
  
  
        //to modaladditem
        data_item_filter: [],
        isMarketing: false,
        itemEdited: {
          marketing_id: "",
          kategori_id: "",
          item_id: "",
  
        },
        paymentEdited: {
          id: "",
          payment_method: "",
          payment_amount: "",
          payment_notes: "",
          payment_date: new Date(),
        },
  
  
  
        // cek deposit
        status_item_deposit: false,
  
        //cek payment
        payment_active: "",
  
        //test 
        batas_payment_edit: 0,
  
        //rate 
        rate_active: "",
  
        pass_data: {
          from: "",
        },
  
        //bank
        bankCek: false,
        bank_selected: "",

        active_page: "umum",
        isActive: true,
  
      };
    },
    computed: {
      totalAmount() {
  
        if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
          return 0;
        } else {
          const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
            parseInt(currentValue.item_total), 0)
          return total
        }
      },
      total_payment() {
        if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
          return 0;
        } else {
          const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
            parseInt(currentValue.payment_amount), 0)
          return total
        }
      },
  
      batas_payment() {
        let payment = 0;
        let amount = 0;
        if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
          payment = 0;
        } else {
          const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
            parseInt(currentValue.payment_amount), 0)
          payment = total;
        }
        if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
          amount = 0;
        } else {
          const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
            parseInt(currentValue.item_total), 0)
          amount = total
        }
  
  
        return amount - payment;
      }
  
    },
    watch: {
      'bank_selected': {
        handler(newValue) {
          this.bankCek = false;
        }
      },
      'currentData.suplier_detail.id': {
        handler(newValue) {
          this.currentData.invoice_detail = [];
          this.currentData.invoice_payment = [];
  
          this.cek_rate_active();
  
        }
      },
      'currentData.discount_type': {
        handler(newValue) {
          if (this.currentData.calculated_discount == '' || (this.currentData.calculated_discount > 100 && newValue == 'persen')
            || (this.currentData.calculated_discount < 0) || (newValue == 'rupiah' && this.currentData.calculated_discount > this.totalAmount)) {
            this.currentData.calculated_discount = 0;
          }
          if (newValue == 'rupiah') {
            this.currentData.discount_amount = this.currentData.calculated_discount;
          } else if (newValue == 'persen' && this.totalAmount > 0) {
            this.currentData.discount_amount = parseInt(this.currentData.calculated_discount) * (parseInt(this.totalAmount)) / 100;
          } else {
            this.currentData.discount_amount = 0;
          }
  
          this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
        }
      },
      'currentData.calculated_discount': {
        handler(newValue) {
          if (this.currentData.calculated_discount == '' || (newValue > 100 && this.currentData.discount_type == 'persen')
            || newValue < 0 || (this.currentData.discount_type == 'rupiah' && newValue > this.totalAmount)) {
            this.currentData.calculated_discount = 0;
          }
          if (this.currentData.discount_type == 'rupiah') {
            this.currentData.discount_amount = newValue;
          } else if (this.currentData.discount_type == 'persen' && this.totalAmount > 0) {
            this.currentData.discount_amount = parseInt(newValue) * (parseInt(this.totalAmount)) / 100;
          } else {
            this.currentData.discount_amount = 0;
          }
  
  
          this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
        }
      },
      'currentData.tax_rate': {
        handler(newValue) {
          if ((newValue > 100 && this.currentData.tax_type == 'persen') || newValue < 0) {
            this.currentData.tax_rate = 0;
          }
  
          if (this.currentData.tax_type == 'persen' && this.totalAmount > 0) {
            this.currentData.tax_amount = parseInt(newValue) * (parseInt(this.totalAmount)) / 100;
          } else {
            this.currentData.tax_amount = 0;
          }
          this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
        }
      }
    },
  
    created() {
      ShowLoading();
      const token = Cookies.get("token_refresh");
  
      this.config = {
        headers: {
          Authorization: `Bearer ` + token,
        },
        timeout: 30000,
      }
  
      // get needed data
      this.getData();
      this.currentData.invoice_date = new Date()
      this.currentData.payment_date = new Date()
      this.currentData.due_date = new Date()
      CloseLoading();
  
  
  
  
    },
  
    methods: {
      rupiah,
      activePage(page) {
          this.active_page = page;
          localStorage.setItem("active_page", page)
      },
      selectAll(event) {
        // Select all text when input field is focused
        event.target.select();
      },
      goPageCallback(url, callback) {
        localStorage.setItem('callback', callback);
        goPage(url);
  
      },
      cek_deposit() {
        const index = this.currentData.invoice_detail.findIndex((record) => record.isdeposit == 'AKTIF');
        const indexPayment = this.data_payments.findIndex((record) => record.nama == 'DEPOSIT');
  
        const indexsuplier = this.data_suplier.findIndex((record) => record.id == this.currentData.suplier_detail.id);
        if (index > -1) {
          this.status_item_deposit = true;
        } else {
          this.status_item_deposit = false;
        }
  
        if ((indexPayment > -1 && index > -1) || (indexsuplier > -1 && this.data_suplier[indexsuplier].amount_deposit <= 0)) {
          this.data_payments.splice(indexPayment, 1);
          if (this.currentData.payment_method == 'DEPOSIT') {
            this.currentData.payment_method = "";
          }
        } else if (indexsuplier > -1 && this.data_suplier[indexsuplier].amount_deposit > 0 && indexPayment < 0) {
          this.data_payments.push({ text: 'DEPOSIT (' + rupiah(this.data_suplier[indexsuplier].amount_deposit) + ')', value: this.data_payments.length, nama: 'DEPOSIT' })
        }
  
      },
      handleKategoriSelected(item) {
        if (item != "") {
          this.data_item_filter = this.dataItemsFilterKategori(item);
        } else {
          this.data_item_filter = this.data_item;
        }
  
      },
  
      dataItemsFilterKategori(id) {
        return this.data_item.filter(item => {
          // Apply your filtering condition
          if (item.id_kategori == id) {
            // Modify the 'value' attribute here
            item.text = item.nama;// Your modification logic here;
            return true; // Keep this item in the filtered result
          }
          return false; // Exclude this item from the filtered result
        });
      },
      handleItemSelected(item) {
        this.isMarketing = item;
      },
      handleItemAdd(item) {
  
        const index = this.data_item.findIndex((record) => record.id == item.id_items);
        const indexMarketing = this.data_marketing.findIndex((record) => record.id == item.id_marketing);
        const indexWarehouse = this.data_warehouse.findIndex((record) => record.id == item.id_warehouse);
  
        const indexKeranjangUpdate = this.currentData.invoice_detail.findIndex(
          (record) => record.id == item.id
        );
  
        const indexWarehouseInvoiceDetail = this.currentData.invoice_detail.findIndex(
          (record) => record.id_warehouse == item.id_warehouse && record.id_items == item.id_items
        );
  
  
        const rate_active = this.cek_rate_active();
        let nama_marketing = "";
        if (this.data_item[index].ismarketing == 'AKTIF' && this.data_marketing[indexMarketing] != undefined) {
          nama_marketing = this.data_marketing[indexMarketing].fullname;
        }
  
        let total_harga_item = 0;
        let total_discount = 0;
        if (item.discount_type == 'percent') {
          total_harga_item = item.harga - (item.harga * (item.discount / 100));
          total_discount = (item.harga * (item.discount / 100));
        } else {
          total_harga_item = item.harga - item.discount;
          total_discount = item.discount;
        }
  
  
        const itemPrepare = {
          ...item, name: this.data_item[index].nama,
          isdeposit: this.data_item[index].isdeposit,
          on_bungkus: this.data_item[index].on_bungkus,
          deskripsi: this.data_item[index].deskripsi,
          nama_marketing: nama_marketing,
          rate: item.harga,
          discount_type: item.discount_type,
          discount_amount: item.discount,
          calculated_discount: total_discount,
          item_total: item.qty * parseInt(total_harga_item),
          id_warehouse: item.id_warehouse,
          nama_gudang: this.data_warehouse[indexWarehouse].text,
        };
  
        this.cek_deposit();
  
        if (item.status == 'tambah') {
  
          if (indexWarehouseInvoiceDetail > -1) {
            AlertPopup('error', '', 'Produk dengan gudang tersebut sudah terdaftar', 2000, true);
            return true;
          }
  
          itemPrepare.id = this.currentData.invoice_detail.length;
  
          if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK') {
            this.currentData.invoice_detail.push(itemPrepare);
          } else if (this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF') {
            this.currentData.invoice_detail.push(itemPrepare);
          } else if (this.status_item_deposit == false && this.currentData.invoice_detail.length == 0 && this.data_item[index].isdeposit == 'AKTIF') {
            this.currentData.invoice_detail.push(itemPrepare);
          }
          else {
            AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
          }
        } else {
  
  
  
  
          if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK' && indexKeranjangUpdate > -1) {
            this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;
          } else if ((this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1) || ((this.status_item_deposit == false && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1 && this.currentData.invoice_detail.length == 1))) {
            if (this.data_item[index].isdeposit == 'AKTIF' && this.currentData.invoice_detail.length == 1) {
              this.currentData.invoice_payment = [];
            }
            this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;
  
          } else if (indexKeranjangUpdate == -1) {
            AlertPopup('error', '', 'item tidak ditemukan', 2000, true);
          }
          else {
            AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
          }
        }
        this.cek_deposit();
  
        this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
  
      },
      cek_rate_active() {
        let status = "rate";
        if (this.currentData.suplier_detail.id == "0" || this.currentData.suplier_detail.id == 0) {
          status = 'rate';
        } else {
          const indexsuplier = this.data_suplier.findIndex((record) => record.id === this.currentData.suplier_detail.id);
          if (this.data_suplier[indexsuplier].rate == "0") {
            status = "rate";
          } else {
            status = 'rate' + this.data_suplier[indexsuplier].rate;
          }
        }
        this.rate_active = status;
        return status;
      },
      deleteItem(indexToRemove) {
        this.currentData.invoice_detail.splice(indexToRemove, 1);
        this.cek_deposit();
        if (this.currentData.invoice_detail.length == 0) {
          this.currentData.invoice_payment = [];
        }
        this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
      },
  
      getData() {
  
        // this.data_suplier.push({ id: 0, text: 'Walk in', rate: "0", value: "0" });
  
        const suplier = JSON.parse(localStorage.getItem('suplier'));
        suplier.forEach((item, index) => {
          let item_prepare = { ...item, value: item.id, text: item.fullname };
          this.data_suplier.push(item_prepare)
        });
  
  
  
  
        //get data kategori 
        const kategori = JSON.parse(localStorage.getItem('kategori'));
        kategori.forEach((item, index) => {
          let item_prepare = { ...item, value: item.id, text: item.nama };
          this.data_kategori.push(item_prepare)
        });
  
        const item = JSON.parse(localStorage.getItem('item'));
        item.forEach((item, index) => {
          let item_prepare = { ...item, value: item.id, text: item.nama, };
          this.data_item.push(item_prepare)
        });
  
        console.log(this.data_item);
  
        // const list_items_in_warehouse = JSON.parse(localStorage.getItem('list_items_in_warehouse'));
        // list_items_in_warehouse.forEach((item, index) => {
        //   let item_prepare = { ...item, value: item.id, text: item.id_warehouse, };
        //   this.data_list_items_in_warehouse.push(item_prepare)
        // });
  
        const data_warehouse = JSON.parse(localStorage.getItem('warehouse'));
        data_warehouse.forEach((item, index) => {
          let item_prepare = { ...item, value: item.id, text: item.nama, };
          this.data_warehouse.push(item_prepare)
        });
  
        const data_ekspedisi = JSON.parse(localStorage.getItem('ekspedisi'));
        data_ekspedisi.forEach((item, index) => {
          let item_prepare = { ...item, value: item.id, text: item.nama, };
          this.data_ekspedisi.push(item_prepare)
        });
  
  
  
  
  
        if (localStorage.getItem('pass_data') != null) {
          this.pass_data = JSON.parse(localStorage.getItem('pass_data'));
  
          if (this.pass_data.from == 'suplier') {
            const item_prepare = { value: this.pass_data.id, text: this.pass_data.nama };
            this.data_suplier.unshift(item_prepare);
  
          } else if (this.pass_data.from == 'marketing') {
            const item_prepare = { value: this.pass_data.id, text: this.pass_data.nama };
            this.data_marketing.unshift(item_prepare);
  
          }
  
        }
  
        if (localStorage.getItem('pass_data') != null) {
          if (this.pass_data.from == 'marketing') {
            localStorage.setItem('marketing', JSON.stringify(this.data_marketing));
          } else if (this.pass_data.from == 'suplier') {
            localStorage.setItem('suplier', JSON.stringify(this.data_suplier));
          }
          localStorage.removeItem('pass_data');
        }
  
  
  
  
      },
      addItem() {
        if (this.currentData.suplier_detail.id == '') {
          AlertPopup('error', '', 'Silahkan Pilih Pemasok terebih dahulu ', 2000, true);
          return true;
        }
  
        this.itemEdited = {
          marketing_id: "",
          kategori_id: "",
          id_warehouse: "",
          item_id: "",
          qty: 1,
          status: 'tambah',
          discount_type: 'percent',
          harga: 0,
          discount: 0,
        }
  
  
  
        const openButton = this.$refs.buttonopenmodaladditempurchase;
        openButton.click();
  
      },
      editItem(item) {
  
        const index = this.data_item.findIndex((record) => record.id == item.id_items);
        const indexKategori = this.data_kategori.findIndex((record) => record.id == this.data_item[index].id_kategori);
  
        this.itemEdited = {
          marketing_id: item.id_marketing,
          kategori_id: this.data_kategori[indexKategori].id,
          item_id: item.id_items,
          qty: item.qty,
          status: 'edit',
          harga: parseInt(item.rate),
          discount_type: item.discount_type,
          discount_amount: parseInt(item.discount_amount),
          id_warehouse: item.id_warehouse,
          id: item.id,
        }
        const openButton = this.$refs.buttonopenmodaladditempurchase;
        openButton.click();
      },
  
  
  
      //payment
      handlePaymentAdd(item) {
  
        const indexKeranjangUpdate = this.currentData.invoice_payment.findIndex(
          (record) => record.id == item.id
        );
        item.payment_method = this.data_payments[item.payment_method].nama;
  
        if (item.status == 'tambah') {
          item.id = this.currentData.invoice_payment.length;
  
          this.currentData.invoice_payment.push(item);
        } else {
          this.currentData.invoice_payment[indexKeranjangUpdate] = item;
        }
      },
  
  
      addPayment() {
        const paymentobject = {
          id: "",
          payment_method: "",
          payment_amount: rupiahInput(0),
          payment_notes: "",
          payment_date: new Date(),
          status: 'tambah'
        }
        this.paymentEdited = paymentobject;
        const openButton = this.$refs.buttonopenmodalpayment;
        openButton.click();
  
  
      },
      editPayment(id, payment_amount, payment_method, payment_date, payment_notes) {
  
        const indexPayment = this.data_payments.findIndex((list) => list.nama == payment_method);
        const paymentobject = {
          id: id,
          payment_method: indexPayment,
          payment_amount: rupiahInput(parseInt(payment_amount)),
          payment_notes: payment_notes,
          payment_date: new Date(payment_date),
          status: 'update'
        }
        // total amount 
        let amount = 0;
        if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
          amount = 0;
        } else {
          const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
            parseInt(currentValue.item_total), 0)
          amount = total;
        }
  
        let payment = 0;
        if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
          payment = 0;
        } else {
          const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
            parseInt(currentValue.payment_amount), 0)
          payment = total
        }
  
        this.batas_payment_edit = parseInt(amount) - parseInt(payment) + parseInt(payment_amount);
  
        this.paymentEdited = paymentobject;
        const openButton = this.$refs.buttonopenmodalpayment;
        openButton.click();
      },
      deletePayment(index) {
        this.currentData.invoice_payment.splice(index, 1);
  
  
  
      },
  
      convertDate(value) {
        const date = new Date(value);
  
        // Use built-in methods to get year, month, and day
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      update() {
        if (this.currentData.invoice_detail.length == 0) {
          AlertPopup("error", "", 'Please select item for sale', 1500, false);
          return true;
        }
  
  
  
        ShowLoading();
        let formData = new FormData();
        const currentDate = new Date().toISOString().split('T')[0];
  
        const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.item_total), 0);
        let item_id = [];
        let item_qty = [];
        let item_warehouse = [];
        let item_rate = [];
        let item_discount_type = [];
        let item_discount_amount = [];
        let item_is_tax = [];
        let item_name = [];
        let item_calculated_discount = [];
        let item_total = [];
        let item_isdeposit = [];
        let item_id_marketing = [];
        let payment_amount = [0];
        let payment_method = [""];
        let payment_date = [""];
        let payment_notes = [""];
  
  
        this.currentData.invoice_detail.forEach((item) => {
          item_id.push(item.id_items)
          item_qty.push(item.qty)
  
          // const indexItem = this.data_items.findIndex((list) => list.id === item.id_items);
          item_rate.push(item.rate);
          item_discount_type.push(item.discount_type);
          item_discount_amount.push(item.discount_amount);
          item_is_tax.push(0);
          item_name.push(item.name);
          item_calculated_discount.push(item.calculated_discount);
          item_total.push(item.item_total);
          item_isdeposit.push(item.isdeposit);
          item_id_marketing.push(item.id_marketing);
          item_warehouse.push(item.id_warehouse);
  
        });
  
        // this.currentData.invoice_payment.forEach((item) => {
        //   payment_notes.push(item.payment_notes);
        //   payment_method.push(item.payment_method);
        //   payment_amount.push(item.payment_amount);
        //   payment_date.push(item.payment_date);
        // })
  
  
        // yang dicomment udh ada
        formData.append("id", this.currentData.id);
        formData.append("invoice_date", this.convertDate(this.currentData.invoice_date)); //udh ada
        formData.append("due_date", this.convertDate(this.currentData.due_date)); //udh ada 
        formData.append("terms", 0); //kosong
        formData.append("po_number", this.currentData.po_number); //kosong
        formData.append("id_suplier", this.currentData.suplier_detail.id); //udh ada
        formData.append("subtotal", this.totalAmount);// udh ada 
        formData.append("discount_type", this.currentData.discount_type); //kosong
        formData.append("discount_amount", this.currentData.discount_amount);//kosong
        formData.append("tax_type", this.currentData.tax_type);//kosong
        formData.append("tax_rate", this.currentData.tax_rate);//kosong
        formData.append("tax_name", 'PPN');//kosong
        formData.append("tax_amount", this.currentData.tax_amount);
        formData.append("payment_date", payment_date); //udh ada
        formData.append("payment_amount", payment_amount);
        formData.append("payment_method", payment_method);
        formData.append("payment_notes", payment_notes); //kosong
        formData.append("unpaid_amount", 0);//kosong
        formData.append("grand_total", this.currentData.grand_total); // udh ada 
        formData.append("notes", ''); //kosong
        formData.append("payment_info", ''); //kosong
        formData.append("bank_name", ""); //kosong
        formData.append("bank_account_number", ""); //kosong
        formData.append("bank_account_name", ""); //kosong
        formData.append("calculated_discount", this.currentData.calculated_discount); //kosong
  
  
        formData.append("item_id", item_id);// udh ada
        formData.append("item_qty", item_qty); //udh ada
        formData.append("item_rate", item_rate); //udh ada
        formData.append("item_discount_type", item_discount_type); //kosong
        formData.append("item_discount_amount", item_discount_amount); //kosong
        formData.append("item_is_tax", item_is_tax); //kosong
        formData.append("item_name", item_name); //kosong
        formData.append("item_warehouse", item_warehouse); //kosong
        formData.append("item_calculated_discount", item_calculated_discount); //kosong
        formData.append("item_total", item_total);// udh ada 
        formData.append("item_isdeposit", item_isdeposit); // udh ada 
        formData.append("item_id_marketing", item_id_marketing);// udh ada
        formData.append("quotation_term", this.currentData.quotation_term);// udh ada
        formData.append("quotation_note", this.currentData.quotation_note);// udh ada
        formData.append("id_marketing", this.currentData.id_marketing);
        formData.append("po_number", this.currentData.po_number);
        formData.append("code_project", this.currentData.code_project);
  
        for (let [key, value] of formData.entries()) {
          console.log(`${key}: ${value}`);
        }
        // CloseLoading();
        // return false;
  
  
        if (process.env.NODE_ENV === "development") {
          this.api = base_url + 'purchase_insert';
        } else {
          this.api = base_url + 'purchase_insert';
        }
  
        axios
          .post(this.api, formData, this.config)
          .then((response) => {
            if (response.data.status == true) {
              AlertBottom(response.data.message);
              goPage('purchase');
              CloseLoading();
            }
  
  
            if (response.data.status == false) {
              AlertPopup("error", "", response.data.message, 1500, false);
            }
          })
          .catch((error) => {
  
            ErrorConnectionTimeOut(error);
          });
      }
  
  
    },
  
  };
</script>
  