<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar" :class="$route.meta.sidebarClass">
    
    <div class="slimScrollDiv">
      
      <div class="sidebar-inner slimscroll " >
        
        <!-- <div class="d-flex align-items-center justify-content-end ">

          <a :class="$route.meta.hideClass" id="toggle_btn" href="javascript:void(0);" class="btn rounded-circle p-2  circle-btn ">
            <vue-feather type="chevrons-left" class="feather-16" style="color: #fff;"></vue-feather>
          </a>
        </div> -->
        <perfect-scrollbar class="scroll-area" :settings="settings" @ps-scroll-y="saveScrollPosition"
          ref="scrollableDiv">
          <div id="sidebar-menu" class="sidebar-menu mb-5">
            <ul>
              <li class="submenu-open">
                <h6 class="submenu-hdr">BIG DATA</h6>
                <ul>
                  <li v-bind:class="{
                    active: currentPath == 'index' || currentPath == 'index',
                  } ">
                    <router-link to="index" > <vue-feather type="grid"></vue-feather><span> Dashboard</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'report' || currentPath == 'report',
                  }">
                    <router-link to="report"> <vue-feather type="file-text"></vue-feather><span> Laporan</span>
                    </router-link>
                  </li>



                </ul>
              </li>
              <li class="submenu-open">
                <h6 class="submenu-hdr">MAIN</h6>
                <ul>
                  <!-- <li v-bind:class="{
                    active: currentPath == 'adddaftar_bank' || currentPath == 'editdaftar_bank' || currentPath == 'daftar_bank',
                  }" v-if="auth_menu.some(item => item.menu === 'Daftar_bank')">
                    <router-link to="daftar_bank"><vue-feather type="dollar-sign"></vue-feather><span> Master Data</span>
                    </router-link>
                  </li> -->
                  <li class="submenu-open">
                    <a v-bind:class="masterData ? 'active' : 'notactive'" href="#masterData"
                      data-bs-toggle="collapse" role="button" :aria-expanded="currentPath == 'suplier' || currentPath == 'customer' || currentPath == 'daftar_bank' || currentPath == 'payment' || currentPath == 'ekspedisi' ? 'true' : 'false'" aria-controls="masterData"
                      v-on:click="masterData = !masterData" :class="{ subdrop: masterData }"><vue-feather
                        type="database"></vue-feather><span>Master Data</span><span class="menu-arrow"></span></a>
                    <ul class="collapse menu-dropdown sub-menu" id="masterData"  v-bind:class="{
                        show: currentPath == 'suplier' || currentPath == 'customer' || currentPath == 'daftar_bank' || currentPath == 'payment' || currentPath == 'ekspedisi',
                      }">
                      <li>
                        <router-link :class="currentPath == 'addsuplier' || currentPath == 'editsuplier'
                            ? 'active'
                            : 'notactive'
                          " to="suplier">Supplier</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addcustomer' || currentPath == 'editcustomer' || currentPath == 'customer'
                            ? 'active'
                            : 'notactive'
                          " to="customer">Pelanggan</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'adddaftar_bank' || currentPath == 'editdaftar_bank'
                            ? 'active'
                            : 'notactive'
                          " to="daftar_bank">Bank</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addpayment' || currentPath == 'editpayment' || currentPath == 'payment'
                            ? 'active'
                            : 'notactive'
                          " to="payment">Metode Pembayaran</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addekspedisi' || currentPath == 'editekspedisi' || currentPath == 'ekspedisi'
                            ? 'active'
                            : 'notactive'
                          " to="ekspedisi">Ekspedisi</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'adddepartemen' || currentPath == 'editdepartemen'
                            ? 'active'
                            : 'notactive'
                          " to="departemen">Departemen</router-link>
                      </li>
                      
                    </ul>
                  </li>
                  <li class="submenu-open">
                    <a v-bind:class="inventoryMenu ? 'active' : 'notactive'" href="#InventoryMenu"
                      data-bs-toggle="collapse" role="button" :aria-expanded="currentPath == 'category' || currentPath == 'product' || currentPath == 'merek' || currentPath == 'warehouse' || currentPath == 'movement_item' ? 'true' : 'false'" aria-controls="InventoryMenu"
                      v-on:click="inventoryMenu = !inventoryMenu" :class="{ subdrop: inventoryMenu }"><vue-feather
                        type="shuffle"></vue-feather><span>Inventory</span><span class="menu-arrow"></span></a>
                    <ul class="collapse menu-dropdown sub-menu" v-bind:class="{
                        show: currentPath == 'category' || currentPath == 'product' || currentPath == 'merek' || currentPath == 'warehouse' || currentPath == 'movement_item',
                      }" id="InventoryMenu">
                      <li>
                        <router-link :class="currentPath == 'addcategory' || currentPath == 'editcategory'
                            ? 'active'
                            : 'notactive'
                          " to="category">Kategori Produk</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addmerek' || currentPath == 'editmerek' || currentPath == 'merek'
                            ? 'active'
                            : 'notactive'
                          " to="merek">Merek</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addgroupproduct' || currentPath == 'editgroupproduct'
                            ? 'active'
                            : 'notactive'
                          " to="groupproduct">Group Produk</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addproduct' || currentPath == 'editproduct'
                            ? 'active'
                            : 'notactive'
                          " to="product">Produk</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addproductrakitan' || currentPath == 'editproductrakitan'
                            ? 'active'
                            : 'notactive'
                          " to="productrakitan">Produk Rakitan</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addwarehouse' || currentPath == 'editwarehouse' || currentPath == 'warehouse'
                            ? 'active'
                            : 'notactive'
                          " to="warehouse">Gudang</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addmovement_item' || currentPath == 'editmovement_item' || currentPath == 'movement_item'
                            ? 'active'
                            : 'notactive'
                          " to="movement_item">Perpindahan Barang</router-link>
                      </li>
                      
                      
                    </ul>
                  </li>
                  <li class="submenu-open">
                    <a v-bind:class="masterData ? 'active' : 'notactive'" href="#buyerMenu"
                      data-bs-toggle="collapse" role="button" :aria-expanded="currentPath == 'purchase' || currentPath == 'purchaseorder' || currentPath == 'goodsreceipt' || currentPath == 'purchaseinvoice' || currentPath == 'retourpurchase' || currentPath == 'downpayment' || currentPath == 'paymentorder' ? 'true' : 'false'" aria-controls="buyerMenu"
                      v-on:click="masterData = !masterData" :class="{ subdrop: masterData }"><vue-feather
                        type="shopping-cart"></vue-feather><span>Pembelian</span><span class="menu-arrow"></span></a>
                    <ul class="collapse menu-dropdown sub-menu" id="buyerMenu" v-bind:class="{
                        show: currentPath == 'purchase' || currentPath == 'purchaseorder' || currentPath == 'goodsreceipt' || currentPath == 'purchaseinvoice' || currentPath == 'retourpurchase' || currentPath == 'downpayment' || currentPath == 'paymentorder'
                        }" >
                      <li>
                        <router-link :class="currentPath == 'addpurchase' || currentPath == 'editpurchase' || currentPath == 'purchase' 
                            ? 'active'
                            : 'notactive'
                          " to="purchase">Permintaan Pembelian</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addpurchaseorder' || currentPath == 'editpurchaseorder' || currentPath == 'purchaseorder'
                            ? 'active'
                            : 'notactive'
                          " to="purchaseorder">Pesanan Pembelian</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addgoodsreceipt' || currentPath == 'editgoodsreceipt' || currentPath == 'goodsreceipt'
                            ? 'active'
                            : 'notactive'
                          " to="goodsreceipt">Penerimaan Barang</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addpurchaseinvoice' || currentPath == 'editpurchaseinvoice' || currentPath == 'purchaseinvoice'
                            ? 'active'
                            : 'notactive'
                          " to="purchaseinvoice">Faktur Pembelian</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addretourpurchase' || currentPath == 'editretourpurchase' || currentPath == 'retourpurchase'
                            ? 'active'
                            : 'notactive'
                          " to="retourpurchase">Retur Pembelian</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'adddownpayment' || currentPath == 'editdownpayment' || currentPath == 'downpayment'
                            ? 'active'
                            : 'notactive'
                          " to="downpayment">Uang Muka Pembelian</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addpaymentorder' || currentPath == 'editpaymentorder' || currentPath == 'paymentorder'
                            ? 'active'
                            : 'notactive'
                          " to="paymentorder">Perintah Pembayaran</router-link>
                      </li>
                      
                      
                    </ul>
                  </li>
                  
                  <li class="submenu-open">
                    <!-- v-bind:class="{
                    active: currentPath == 'products' || currentPath == 'products',
                  }" -->
                    <a v-bind:class="masterData ? 'active' : 'notactive'" href="#sellingMenu"
                      data-bs-toggle="collapse" role="button" :aria-expanded="currentPath == 'penawaran' || currentPath == 'ordersales' || currentPath == 'pesananpenjualan' || currentPath == 'pengirimanpesanan' || currentPath == 'fakturpenjualan' || currentPath == 'uangmukapenjualan' || currentPath == 'penerimaanpenjualan' ? 'true' : 'false'" aria-controls="sellingMenu"
                      v-on:click="masterData = !masterData" :class="{ subdrop: masterData }"><i class='bx bx-receipt me-2 pe-1'></i><span>Penjualan</span><span class="menu-arrow"></span></a>
                    <ul class="collapse menu-dropdown sub-menu" id="sellingMenu" v-bind:class="{
                        show: currentPath == 'penawaran' || currentPath == 'ordersales' || currentPath == 'pesananpenjualan' || currentPath == 'pengirimanpesanan' || currentPath == 'fakturpenjualan' || currentPath == 'uangmukapenjualan' || currentPath == 'penerimaanpenjualan'
                      }">
                      <li>
                        <router-link :class="currentPath == 'addpenawaran' || currentPath == 'editpenawaran' || currentPath == 'penawaran'
                            ? 'active'
                            : 'notactive'
                          " to="penawaran">Penawaran</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addpesananpenjualan' || currentPath == 'editpesananpenjualan' ||  currentPath == 'pesananpenjualan'
                            ? 'active'
                            : 'notactive'
                          " to="pesananpenjualan">Pesanan Penjualan</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addpengirimanpesanan' || currentPath == 'editpengirimanpesanan' || currentPath == 'pengirimanpesanan'
                            ? 'active'
                            : 'notactive'
                          " to="pengirimanpesanan">Pengiriman Pesanan</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addfakturpenjualan' || currentPath == 'editfakturpenjualan' || currentPath == 'fakturpenjualan' 
                            ? 'active'
                            : 'notactive'
                          " to="fakturpenjualan">Faktur Penjualan</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'adduangmukapenjualan' || currentPath == 'edituangmukapenjualan' || currentPath == 'uangmukapenjualan'
                            ? 'active'
                            : 'notactive'
                          " to="uangmukapenjualan">Uang Muka Penjualan</router-link>
                      </li>
                      <li>
                        <router-link :class="currentPath == 'addpenerimaanpenjualan' || currentPath == 'editpenerimaanpenjualan' || currentPath == 'penerimaanpenjualan'
                            ? 'active'
                            : 'notactive'
                          " to="penerimaanpenjualan">Penerimaan Penjualan</router-link>
                      </li>
                      
                      
                      
                    </ul>
                  </li>
                  <li class="submenu-open">
                    <a v-bind:class="masterData ? 'active' : 'notactive'" href="#crmMenu"
                      data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="crmMenu"
                      v-on:click="masterData = !masterData" :class="{ subdrop: masterData }"><vue-feather
                        type="bar-chart-2"></vue-feather><span>CRM</span><span class="menu-arrow"></span></a>
                    <ul class="collapse menu-dropdown sub-menu" id="crmMenu">
                      <li>
                        <router-link :class="currentPath == 'addproductcategory' || currentPath == 'editproductcategory'
                            ? 'active'
                            : 'notactive'
                          " to="productcategory">CRM</router-link>
                      </li>
                      
                      
                      
                    </ul>
                  </li>
                  <li class="submenu-open" >
                    <!-- v-bind:class="{
                      active: currentPath == 'products' || currentPath == 'products',
                    }" -->
                    <a v-bind:class="kasBank ? 'active' : 'notactive'"  href="#kasBank" 
                      data-bs-toggle="collapse" role="button" :aria-expanded="currentPath == 'daftar_bank' ? 'true' : 'false'" aria-controls="kasBank"
                      v-on:click="kasBank = !kasBank" :class="{ subdrop: kasBank }"><i class='bx bxs-bank me-2 pe-1'></i><span>Kas Bank</span><span class="menu-arrow"></span></a>
                    <ul class="collapse menu-dropdown sub-menu" v-bind:class="{
                        show: currentPath == 'daftar_bank' || currentPath == 'daftar_bank',
                      }" id="kasBank">
                      <li v-bind:class="{
                        active: currentPath == 'daftar_bank' || currentPath == 'daftar_bank',
                      }">
                        <router-link :class="currentPath == 'adddaftar_bank' || currentPath == 'editdaftar_bank' || currentPath == 'daftar_bank'
                            ? 'active'
                            : 'notactive'
                          " to="daftar_bank">Kas Bank</router-link>
                      </li>
                      
                      
                      
                    </ul>
                  </li>
                  <li class="submenu-open">
                    <a v-bind:class="masterData ? 'active' : 'notactive'" href="#finance"
                      data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="finance"
                      v-on:click="masterData = !masterData" :class="{ subdrop: masterData }"><i class='bx bx-wallet me-2 pe-1'></i><span>Finance</span><span class="menu-arrow"></span></a>
                    <ul class="collapse menu-dropdown sub-menu" id="finance">
                      <li>
                        <router-link :class="currentPath == 'addproductcategory' || currentPath == 'editproductcategory'
                            ? 'active'
                            : 'notactive'
                          " to="productcategory">Finance</router-link>
                      </li>
                      
                      
                      
                    </ul>
                  </li>
                  <li class="submenu-open">
                    <a v-bind:class="masterData ? 'active' : 'notactive'" href="#accounting"
                      data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="accounting"
                      v-on:click="masterData = !masterData" :class="{ subdrop: masterData }"><vue-feather
                        type="printer"></vue-feather><span>Accounting</span><span class="menu-arrow"></span></a>
                    <ul class="collapse menu-dropdown sub-menu" id="accounting">
                      <li>
                        <router-link :class="currentPath == 'addproductcategory' || currentPath == 'editproductcategory'
                            ? 'active'
                            : 'notactive'
                          " to="productcategory">Accounting</router-link>
                      </li>
                      
                      
                      
                    </ul>
                  </li>
                  <li class="submenu-open">
                    <a v-bind:class="masterData ? 'active' : 'notactive'" href="#tax"
                      data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="tax"
                      v-on:click="masterData = !masterData" :class="{ subdrop: masterData }"><vue-feather
                        type="percent"></vue-feather><span>Tax</span><span class="menu-arrow"></span></a>
                    <ul class="collapse menu-dropdown sub-menu" id="tax">
                      <li>
                        <router-link :class="currentPath == 'addproductcategory' || currentPath == 'editproductcategory'
                            ? 'active'
                            : 'notactive'
                          " to="productcategory">Tax</router-link>
                      </li>
                      
                      
                      
                    </ul>
                  </li>
                  

                </ul>
              </li>
              


              



              <!-- <li class="submenu-open">
                <h6 class="submenu-hdr">Operasional AP</h6>
                <ul>
                  <li v-bind:class="{
                    active: currentPath == 'addsuplier' || currentPath == 'editsuplier' || currentPath == 'suplier',
                  }" v-if="auth_menu.some(item => item.menu === 'Suplier')">
                    <router-link to="suplier"><vue-feather type="shopping-bag"></vue-feather><span> Pemasok</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addpurchase' || currentPath == 'editpurchase' || currentPath == 'purchase',
                  }" v-if="auth_menu.some(item => item.menu === 'Purchase')">
                    <router-link to="purchase"><vue-feather type="shopping-cart"></vue-feather><span>PO</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addap' || currentPath == 'editap' || currentPath == 'ap',
                  }" v-if="auth_menu.some(item => item.menu === 'ap')">
                    <router-link to="ap"><vue-feather type="file-text"></vue-feather><span> AP</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="submenu-open">
                <h6 class="submenu-hdr">Operasional AR</h6>
                <ul>
                  <li v-bind:class="{
                    active: currentPath == 'addkategori_pelanggan' || currentPath == 'editkategori_pelanggan' || currentPath == 'kategori_pelanggan',
                  }" v-if="auth_menu.some(item => item.menu === 'Kategori_pelanggan')">
                    <router-link to="kategori_pelanggan"><vue-feather type="users"></vue-feather><span> Kategori
                        Pelanggan</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addcustomer' || currentPath == 'editcustomer' || currentPath == 'customer',
                  }" v-if="auth_menu.some(item => item.menu === 'Customer')">
                    <router-link to="customer"><vue-feather type="user"></vue-feather><span> Pelanggan</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addsales_order' || currentPath == 'editsales_order' || currentPath == 'sales_order',
                  }" v-if="auth_menu.some(item => item.menu === 'Sales_order')">
                    <router-link to="sales_order"><vue-feather type="shopping-bag"></vue-feather><span> SO
                      </span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addinvoice' || currentPath == 'editinvoice' || currentPath == 'invoice',
                  }" v-if="auth_menu.some(item => item.menu === 'Invoice')">
                    <router-link to="invoice"><vue-feather type="credit-card"></vue-feather><span>Sales Ivoice</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addkuitansi' || currentPath == 'editkuitansi' || currentPath == 'kuitansi',
                  }" v-if="auth_menu.some(item => item.menu === 'Kuitansi')">
                    <router-link to="kuitansi"><vue-feather type="file-text"></vue-feather><span> AR</span>
                    </router-link>
                  </li>

                </ul>
              </li>
              <li class="submenu-open">
                <h6 class="submenu-hdr">Pengguna</h6>
                <ul>
                  <li v-bind:class="{
                    active: currentPath == 'user' || currentPath == 'user',
                  }" v-if="auth_menu.some(item => item.menu === 'User')">
                    <router-link to="user"><vue-feather type="codepen"></vue-feather><span> Pengguna</span>
                    </router-link>
                  </li>
                </ul>
              </li> -->
            </ul>
          </div>
          <!-- <div id="sidebar-menu" class="sidebar-menu mb-5">
            <ul>
              <li class="submenu-open">
                <h6 class="submenu-hdr">Utama</h6>
                <ul>
                  <li v-bind:class="{
                    active: currentPath == 'index' || currentPath == 'index',
                  }">
                    <router-link to="index"> <vue-feather type="grid"></vue-feather><span> Dashboard</span>
                    </router-link>
                  </li>



                </ul>
              </li>
              <li class="submenu-open">
                <h6 class="submenu-hdr">Data</h6>
                <ul>
                  <li v-bind:class="{
                    active: currentPath == 'adddaftar_bank' || currentPath == 'editdaftar_bank' || currentPath == 'daftar_bank',
                  }" v-if="auth_menu.some(item => item.menu === 'Daftar_bank')">
                    <router-link to="daftar_bank"><vue-feather type="dollar-sign"></vue-feather><span> Daftar
                        Bank</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addpayment' || currentPath == 'editpayment' || currentPath == 'payment',
                  }" v-if="auth_menu.some(item => item.menu === 'Payment')">
                    <router-link to="payment"><vue-feather type="credit-card"></vue-feather><span> Metode
                        Pembayaran</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addekspedisi' || currentPath == 'editekspedisi' || currentPath == 'ekspedisi',
                  }" v-if="auth_menu.some(item => item.menu === 'Ekspedisi')">
                    <router-link to="ekspedisi"><vue-feather type="truck"></vue-feather><span> Ekspedisi</span>
                    </router-link>
                  </li>

                </ul>
              </li>
              <li class="submenu-open">
                <h6 class="submenu-hdr">Inventaris</h6>
                <ul>
                  <li v-bind:class="{
                    active: currentPath == 'addcategory' || currentPath == 'editcategory' || currentPath == 'category',
                  }" v-if="auth_menu.some(item => item.menu === 'Category')">
                    <router-link to="category"><vue-feather type="codepen"></vue-feather><span> Kategori</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addmerek' || currentPath == 'editmerek' || currentPath == 'merek',
                  }" v-if="auth_menu.some(item => item.menu === 'Merek')">
                    <router-link to="merek"><vue-feather type="tag"></vue-feather><span> Merek</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addproduct' || currentPath == 'editproduct' || currentPath == 'product',
                  }" v-if="auth_menu.some(item => item.menu === 'Product')">
                    <router-link to="product"> <vue-feather type="box"></vue-feather><span> Produk</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addwarehouse' || currentPath == 'editwarehouse' || currentPath == 'warehouse',
                  }" v-if="auth_menu.some(item => item.menu === 'Warehouse')">
                    <router-link to="warehouse"><vue-feather type="home"></vue-feather><span> Gudang</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addmovement_item' || currentPath == 'editmovement_item' || currentPath == 'movement_item',
                  }" v-if="auth_menu.some(item => item.menu === 'Movement_item')">
                    <router-link to="movement_item"><vue-feather type="package"></vue-feather><span> Perpindahan
                        Barang</span>
                    </router-link>
                  </li>

                </ul>
              </li>



              <li class="submenu-open">
                <h6 class="submenu-hdr">Operasional AP</h6>
                <ul>
                  <li v-bind:class="{
                    active: currentPath == 'addsuplier' || currentPath == 'editsuplier' || currentPath == 'suplier',
                  }" v-if="auth_menu.some(item => item.menu === 'Suplier')">
                    <router-link to="suplier"><vue-feather type="shopping-bag"></vue-feather><span> Pemasok</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addpurchase' || currentPath == 'editpurchase' || currentPath == 'purchase',
                  }" v-if="auth_menu.some(item => item.menu === 'Purchase')">
                    <router-link to="purchase"><vue-feather type="shopping-cart"></vue-feather><span>PO</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addap' || currentPath == 'editap' || currentPath == 'ap',
                  }" v-if="auth_menu.some(item => item.menu === 'ap')">
                    <router-link to="ap"><vue-feather type="file-text"></vue-feather><span> AP</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="submenu-open">
                <h6 class="submenu-hdr">Operasional AR</h6>
                <ul>
                  <li v-bind:class="{
                    active: currentPath == 'addkategori_pelanggan' || currentPath == 'editkategori_pelanggan' || currentPath == 'kategori_pelanggan',
                  }" v-if="auth_menu.some(item => item.menu === 'Kategori_pelanggan')">
                    <router-link to="kategori_pelanggan"><vue-feather type="users"></vue-feather><span> Kategori
                        Pelanggan</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addcustomer' || currentPath == 'editcustomer' || currentPath == 'customer',
                  }" v-if="auth_menu.some(item => item.menu === 'Customer')">
                    <router-link to="customer"><vue-feather type="user"></vue-feather><span> Pelanggan</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addsales_order' || currentPath == 'editsales_order' || currentPath == 'sales_order',
                  }" v-if="auth_menu.some(item => item.menu === 'Sales_order')">
                    <router-link to="sales_order"><vue-feather type="shopping-bag"></vue-feather><span> SO
                      </span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addinvoice' || currentPath == 'editinvoice' || currentPath == 'invoice',
                  }" v-if="auth_menu.some(item => item.menu === 'Invoice')">
                    <router-link to="invoice"><vue-feather type="credit-card"></vue-feather><span>Sales Ivoice</span>
                    </router-link>
                  </li>
                  <li v-bind:class="{
                    active: currentPath == 'addkuitansi' || currentPath == 'editkuitansi' || currentPath == 'kuitansi',
                  }" v-if="auth_menu.some(item => item.menu === 'Kuitansi')">
                    <router-link to="kuitansi"><vue-feather type="file-text"></vue-feather><span> AR</span>
                    </router-link>
                  </li>

                </ul>
              </li>
              <li class="submenu-open">
                <h6 class="submenu-hdr">Pengguna</h6>
                <ul>
                  <li v-bind:class="{
                    active: currentPath == 'user' || currentPath == 'user',
                  }" v-if="auth_menu.some(item => item.menu === 'User')">
                    <router-link to="user"><vue-feather type="codepen"></vue-feather><span> Pengguna</span>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div> -->
        </perfect-scrollbar>
      </div>
    </div>
  </div>
  
  <!-- /Sidebar -->
</template>

<style>
.sidebar-overlay.opened {
  display: none !important;
}

.sidebar-menu {
  background-color: #d6b884 !important;
}

.submenu-hdr {
  font-size: 24px !important;
}

.sidebar-inner.slimscroll {
  background-color: #d6b884 !important; 
  /* background-color: black !important;  */
}

.circle-btn {
  width: 40px; /* Adjust width and height as needed */
  height: 40px;
  border-radius: 50%; /* Makes it a full circle */
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translateX(20px) translateY(15px); */
  border: 1px solid #f4f4f4 !important;
  background-color: #d6b884 !important;
  z-index: 9999;
   
}

.sub-menu {
  padding-left: 28px !important;
}


</style>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { goPage } from "@/assets/js/function";
import ThemifyIcon from "vue-themify-icons";
import { MDBIcon } from "mdb-vue-ui-kit";

export default {
  data() {
    return {
      employeeMenu2: false,
      reportMenu2: false,
      managementMenu2: false,
      tipe: "",

      auth_menu: [],


      salesMenu2: false,
      purchaseMenu2: false,
      expenseMenu2: false,
      quotationMenu2: false,
      transferMenu2: false,
      returnMenu2: false,
      peopleMenu2: false,
      placesMenu2: false,
      errorpageMenu2: false,
      userMenu2: false,
      settingMenu2: false,
      // reportMenu: false,
      elementMenu2: false,
      chartMenu2: false,
      iconMenu2: false,
      formMenu2: false,
      tableMenu2: false,
      chatMenu2: false,
      authenticationPageMenu: false,
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
      mainmenulistshow: true,
    };
    //  isactive : true
  },
  beforeMount() {
    this.restoreScrollPosition();
  },

 

  
  
  
  methods: {
    goPage,
    // scrollHanle(evt) {
    //   console.log(evt);
    // },
    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      searchInput.addEventListener("focus", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchInput.addEventListener("keyup", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchOptions.addEventListener("click", () => {
        searchInput.value = "";
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#sidebar")) {
          let a = document
            .querySelector("#sidebar")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },

    saveScrollPosition() {
      const scrollPosition = this.$refs.scrollableDiv.$el.scrollTop;
      localStorage.setItem("scrollPosition", scrollPosition);

    },
    restoreScrollPosition() {
      const scrollPosition = localStorage.getItem("scrollPosition");
      this.$nextTick(() => {
        this.$refs.scrollableDiv.$el.scrollTop = scrollPosition;

      });
    },


  },

  created() {
    this.auth_menu = JSON.parse(localStorage.getItem('auth_menu'));
  },
  mounted() {
    this.tipe = localStorage.getItem('tipe_user');


    // var $wrapper = $(".main-wrapper");
    // $(window).resize(function () {
    //   if ($(".page-wrapper").length > 0) {
    //     var height = $(window).height();
    //     $(".page-wrapper").css("min-height", height);
    //   }
    // });
    // $("body").append('<div class="sidebar-overlay"></div>');
    // $(document).on("click", "#mobile_btn", function () {
    //   $wrapper.toggleClass("slide-nav");
    //   $(".sidebar-overlay").toggleClass("opened");
    //   $("html").addClass("menu-opened");
    //   $("#task_window").removeClass("opened");
    //   return false;
    // });

    // $(document).on("click", "#toggle_btn", function () {
    //   if ($("body").hasClass("mini-sidebar")) {
    //     $("body").removeClass("mini-sidebar");
    //     $(this).addClass("active");
    //     $(".subdrop + ul");
    //     localStorage.setItem("screenModeNightTokenState", "night");
    //     setTimeout(function () {
    //       $("body").removeClass("mini-sidebar");
    //       $(".header-left").addClass("active");
    //     }, 100);
    //   } else {
    //     $("body").addClass("mini-sidebar");
    //     $(this).removeClass("active");
    //     $(".subdrop + ul");
    //     localStorage.removeItem("screenModeNightTokenState", "night");
    //     setTimeout(function () {
    //       $("body").addClass("mini-sidebar");
    //       $(".header-left").removeClass("active");
    //     }, 100);
    //   }
    //   return false;
    // });
    // $(document).on("mouseover", function (e) {
    //   e.stopPropagation();
    //   if ($("body").hasClass("mini-sidebar") && $("#toggle_btn").is(":visible")) {
    //     var targ = $(e.target).closest(".sidebar, .header-left").length;
    //     if (targ) {
    //       $("body").addClass("expand-menu");
    //       $(".subdrop + ul").slideDown();
    //     } else {
    //       $("body").removeClass("expand-menu");
    //       $(".subdrop + ul").slideUp();
    //     }
    //     return false;
    //   }
    // });
    // if (localStorage.getItem("screenModeNightTokenState") == "night") {
    //   setTimeout(function () {
    //     $("body").removeClass("mini-sidebar");
    //     $(".header-left").addClass("active");
    //   }, 100);
    // }

    // $(".submenus").on("click", function () {
    //   $("body").addClass("sidebarrightmenu");
    // });

    // this.auth_menu = JSON.parse(localStorage.getItem('auth_menu'));

    // this.$nextTick(() => {
    //   if (document.getElementById('mainmenulist').innerHTML == '<!--v-if-->') {
    //     this.mainmenulistshow == false;
    //   } else {
    //     this.mainmenulistshow == true;
    //   }

    // });
  },

  components: {
    PerfectScrollbar, ThemifyIcon, MDBIcon, ThemifyIcon,
  },

  computed: {
    currentPath() {
      return this.$route.name;
    },
    employeeMenu() {
      return (
        this.$route.name == "employee" ||
        this.$route.name == "addemployee" ||
        this.$route.name == "editemployee" ||
        this.$route.name == "historyattendance" ||
        this.$route.name == "importattendance" ||
        this.$route.name == "birthday"
      );
    },
    reportMenu() {
      return (
        this.$route.name == "hrsummaryreport" ||
        this.$route.name == "payrollreport" ||
        this.$route.name == "payrollreportsalaryslip" ||
        this.$route.name == "hrdailyreport"
      );
    },
    managementMenu() {
      return (
        this.$route.name == "admin" ||
        this.$route.name == "addadmin" ||
        this.$route.name == "editadmin" ||
        this.$route.name == "shift" ||
        this.$route.name == "addshift" ||
        this.$route.name == "editshift"
      );
    },
    salesMenu() {
      return (
        this.$route.name == "saleslist" ||
        this.$route.name == "sales-details" ||
        this.$route.name == "pos" ||
        this.$route.name == "add-sales" ||
        this.$route.name == "editsalesreturns" ||
        this.$route.name == "edit-sales" ||
        this.$route.name == "salesreturnlists" ||
        this.$route.name == "createsalesreturns"
      );
    },
    purchaseMenu() {
      return (
        this.$route.name == "purchaselist" ||
        this.$route.name == "addpurchase" ||
        this.$route.name == "editpurchase" ||
        this.$route.name == "importpurchase"
      );
    },
    expenseMenu() {
      return (
        this.$route.name == "expenselist" ||
        this.$route.name == "createexpense" ||
        this.$route.name == "editexpense" ||
        this.$route.name == "expensecategory"
      );
    },
    quotationMenu() {
      return (
        this.$route.name == "quotationlist" ||
        this.$route.name == "addquotation" ||
        this.$route.name == "editquotation"
      );
    },
    transferMenu() {
      return (
        this.$route.name == "transferlist" ||
        this.$route.name == "addtransfer" ||
        this.$route.name == "edittransfer" ||
        this.$route.name == "importtransfer"
      );
    },
    returnMenu() {
      return (
        this.$route.name == "salesreturnlist" ||
        this.$route.name == "editsalesreturn" ||
        this.$route.name == "createsalesreturn" ||
        this.$route.name == "createpurchasereturn" ||
        this.$route.name == "editpurchasereturn"
      );
    },
    peopleMenu() {
      return (
        this.$route.name == "customerlist" ||
        this.$route.name == "addcustomer" ||
        this.$route.name == "editcustomer" ||
        this.$route.name == "supplierlist" ||
        this.$route.name == "editsupplier" ||
        this.$route.name == "addsupplier" ||
        this.$route.name == "userlist" ||
        this.$route.name == "edituser" ||
        this.$route.name == "adduser" ||
        this.$route.name == "editstore" ||
        this.$route.name == "storelist" ||
        this.$route.name == "addstore"
      );
    },
    placesMenu() {
      return (
        this.$route.name == "newcountry" ||
        this.$route.name == "editcountry" ||
        this.$route.name == "countrieslist" ||
        this.$route.name == "editstate" ||
        this.$route.name == "newstate" ||
        this.$route.name == "statelist"
      );
    },
    errorpageMenu() {
      return this.$route.name == "error-404" || this.$route.name == "error-500";
    },
    // reportMenu() {
    //   return (
    //     this.$route.name == "purchaseorderreport" ||
    //     this.$route.name == "inventoryreport" ||
    //     this.$route.name == "salesreport" ||
    //     this.$route.name == "invoicereport" ||
    //     this.$route.name == "purchasereport" ||
    //     this.$route.name == "supplierreport" ||
    //     this.$route.name == "customerreport"
    //   );
    // },
    userMenu() {
      return (
        this.$route.name == "newuser" ||
        this.$route.name == "userlists" ||
        this.$route.name == "newuseredit"
      );
    },
    settingMenu() {
      return (
        this.$route.name == "generalsettings" ||
        this.$route.name == "emailsettings" ||
        this.$route.name == "editpermission" ||
        this.$route.name == "createpermission" ||
        this.$route.name == "paymentsettings" ||
        this.$route.name == "currencysettings" ||
        this.$route.name == "grouppermissions" ||
        this.$route.name == "taxrates"
      );
    },
    elementMenu() {
      return (
        this.$route.name == "sweetalerts" ||
        this.$route.name == "tooltip" ||
        this.$route.name == "popover" ||
        this.$route.name == "ribbon" ||
        this.$route.name == "clipboard" ||
        this.$route.name == "drag-drop" ||
        this.$route.name == "rangeslider" ||
        this.$route.name == "rating" ||
        this.$route.name == "toastr" ||
        this.$route.name == "text-editor" ||
        this.$route.name == "counter" ||
        this.$route.name == "scrollbar" ||
        this.$route.name == "spinner" ||
        this.$route.name == "notification" ||
        this.$route.name == "lightbox" ||
        this.$route.name == "stickynote" ||
        this.$route.name == "timeline" ||
        this.$route.name == "form-wizard"
      );
    },
    chartMenu() {
      return (
        this.$route.name == "chart-apex" ||
        this.$route.name == "chart-js" ||
        this.$route.name == "chart-morris" ||
        this.$route.name == "chart-flot"
      );
    },
    iconMenu() {
      return (
        this.$route.name == "icon-fontawesome" ||
        this.$route.name == "icon-feather" ||
        this.$route.name == "icon-ionic" ||
        this.$route.name == "icon-material" ||
        this.$route.name == "icon-pe7" ||
        this.$route.name == "icon-simpleline" ||
        this.$route.name == "icon-themify" ||
        this.$route.name == "icon-weather" ||
        this.$route.name == "icon-typicon" ||
        this.$route.name == "icon-flag"
      );
    },
    formMenu() {
      return (
        this.$route.name == "form-basic-inputs" ||
        this.$route.name == "form-input-groups" ||
        this.$route.name == "form-horizontal" ||
        this.$route.name == "form-vertical" ||
        this.$route.name == "form-mask" ||
        this.$route.name == "form-validation" ||
        this.$route.name == "form-select2" ||
        this.$route.name == "form-fileupload"
      );
    },
    tableMenu() {
      return this.$route.name == "tables-basic" || this.$route.name == "data-tables";
    },
    chatMenu() {
      return (
        this.$route.name == "chat" ||
        this.$route.name == "calendar" ||
        this.$route.name == "email"
      );
    },
    currentPath() {
      return this.$route.name;
    },
  },
};
</script>
