<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">

          <div class="login-img">
            <!-- <img src="../../../assets/img/banner_login.png" alt="img" size="10" /> -->

          </div>
          <div class="login-content">
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <!-- <img src="../../../assets/img/logo.png" alt="img" /> -->
                <img src="../../../assets/img/bja-logo-crop.png" alt="img" class="img-centered" width="180" height="160" style="margin-left: 160px;"/>
                
                

              </div>
              <router-link to="/index" class="login-logo logo-white">
                <!-- <img src="../../../assets/img/logo-white.png" alt="" /> -->
                <img src="../../../assets/img/bja-logo-crop.png" alt="img" class="" width="180" height="160" />

              </router-link>
              <div class="login-userheading">
                <div class="text-center mb-3">
                  <h3>Confirm OTP</h3>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <h4>Please enter the OTP</h4>
                  </div>

                  <div>
                    Didn't get the OTP?
                    <a @click="sendOtp()" class="hover-a">send again</a>
                  </div>
                </div>

              </div>
              <Form class="login" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                <div class="form-login">
                  <!-- <label>OTP</label> -->
                  <div class="form-addons">
                    <Field name="phone" type="text" v-model="phone" class="form-control custom-input" aria-placeholder="" placeholder="OTP"
                      :class="{ 'is-invalid': errors.phone }" :maxlength="6" />
                    <div class="phone-icon position-absolute top-50 start-0 translate-middle-y">
                      <i class='bx bx-phone text-secondary' alt="img" :class="{ 'bottom-inline': errors.phone }"></i>
                    </div>

                    <div class="invalid-feedback">{{ errors.phone }}</div>
                    <div class="emailshow text-danger" id="email"></div>
                    <!-- <img src="../../../assets/img/icons/mail.svg" alt="img" v-if="!(errors.email)" /> -->
                    <div class="position-absolute me-2 end-0 top-0 h-100 d-flex align-items-center"
                      v-if="!(errors.phone)">
                    </div>

                  </div>
                </div>
                <!-- <div class="form-login">
                  <label>Password</label>
                  <div class="pass-group">
                    <Field v-if="showPassword" name="password" type="text" value="123456" class="form-control pass-input"
                      :class="{ 'is-invalid': errors.password }" />
                    <Field v-else name="password" type="password" value="123456" class="form-control pass-input"
                      :class="{ 'is-invalid': errors.password }" />
                    <span @click="toggleShow" class="fas toggle-password" :class="{
                      'fa-eye': showPassword,
                      'fa-eye-slash': !showPassword,
                    }"></span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div> -->
                <!-- <div class="form-login">
                  <div class="alreadyuser">
                    <h4>
                      <router-link to="forgetpassword" class="hover-a">Forgot Password?</router-link>
                    </h4>
                  </div>
                </div> -->
                <div class="form-login">
                  <button type="submit" class="btn btn-login" style="color: black !important; border-radius: 15px;">Confirm</button>
                  <!-- <router-link class="btn btn-login" to="pin"></router-link> -->
                </div>
              </Form>
              <div class="signinform text-center d-flex justify-content-start">
                <div>
                  <router-link to="/" class="hover-a">Change account</router-link>
                </div>
              </div>
              <!-- <div class="form-setlogin">
                <h4>Or sign up with</h4>
              </div> -->
              <!-- <div class="form-sociallink">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      <img src="../../../assets/img/icons/google.png" class="me-2" alt="google" />
                      Sign Up using Google
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <img src="../../../assets/img/icons/facebook.png" class="me-2" alt="google" />
                      Sign Up using Facebook
                    </a>
                  </li>
                </ul>
              </div> -->
            </div>
            <div class="text-center sponsored-img">
                <!-- <p>Powered by </p> -->
                <img src="../../../assets/img/scbd-sponsored.png" alt="Powered by SCBD" class="img-centered">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>

<style scoped>
.login-img {
  background-image: url('../../../assets/img/banner-login-bja.jpg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  /* background-size: 800px; */
  /* background-repeat: no-repeat; */
  background-position: 0px -500px;
}

.custom-input {
  border-radius: 15px !important;
  padding: 10px 15px !important;
  padding-left: 45px !important;
  height: 58px !important;
  text-transform: none !important;

}

.custom-input::placeholder {
  text-transform: none !important;
  font-weight: 300 !important;
  color: #c3c3c3 !important;  
  font-size: 14px !important;
}

.phone-icon {
  left: 12px; 
  margin-left: 12px;
  pointer-events: none; 
  font-size: 24px;
  color: #D9D9D9;
}

.bottom-inline {
  margin-bottom: 22px;
}

.img-centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

</style>



<script>
// import { ref } from "vue";
// import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
// import { router } from "../../../router";
// import VueRouter from "vue-router";
import * as Yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";
import { AlertPopup, CloseLoading, ShowLoading, base_url, timeout, ErrorConnectionTimeOut, goPage } from "@/assets/js/function";


export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      phone: "",

      timer: 0,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },

  // mounted() {
  //   if (localStorage.getItem('SendOTPDate') != null) {
  //     setInterval(this.cekIsSendOTP(), 1000);
  //   }



  // },

  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    cekIsSendOTP() {
      let date_now = new Date();
      date_now = date_now.setSeconds(date_now.getSeconds() + 0);
      const date_local = parseInt(localStorage.getItem('SendOTPDate'));

      if (date_local < date_now) {
        localStorage.setItem('isSendOTP', 'true');

      } else {
        this.timer = parseInt(((date_now - date_local) / 1000) * -1);
        localStorage.getItem('isSendOTP', 'false');
      }
    },

    sendOtp() {
      ShowLoading();
      this.cekIsSendOTP();
      if (localStorage.getItem('isSendOTP') == "false") {
        AlertPopup("error", "", "coba lagi dalam " + this.timer + " detik", 2000, false);
        return true;
      }

      let formData = new FormData();

      formData.append("phone", Cookies.get("useremailphone"));

      let api = "";
      if (process.env.NODE_ENV === "development") {
        api = base_url + 'send_otp';
      } else {
        api = base_url + 'send_otp';
      }

      axios
        .post(api, formData, { timeout: timeout })
        .then((response) => {
          if (response.data.status == true) {
            Cookies.set("token", response.data.token);
            CloseLoading();
            // goPage('confirm-otp');
            AlertPopup("success", "", "OTP has been sent", 2000, false);
            formData.append("phone", localStorage.setItem('isSendOTP', 'false'));

            let currentDate2 = new Date();
            currentDate2 = currentDate2.setSeconds(currentDate2.getSeconds() + 120);
            // console.log(currentDate2);
            localStorage.setItem('SendOTPDate', currentDate2);

          } else {
            // this.showAlert(response.data.message);
            // this.email = "0" + this.email.substring(2);
            // this.isi_button = `<div class="fs-7">Login</div>`;
            AlertPopup("error", "", response.data.message, 2000, false);
          }
        })
        .catch((error) => {
          // this.isi_button = `<div class="fs-7">Login</div>`;

          ErrorConnectionTimeOut(error);
        });
    }

  },
  created() {
    const token = Cookies.get("token");

    if (token) {
      if (token !== 'null' && token.trim() !== '') {
        // Token is set and has a value
        AlertPopup("success", "", "OTP has been sent", 2000, false);
      } else {
        // Token is set but is null or an empty string
        goPage('');
      }
    } else {
      // Token is not set
      goPage('');
    }

  },
  setup() {
    // let users = localStorage.getItem("storedData");
    // if (users === null) {
    //   let password = [
    //     {
    //       phone: "081234567890",
    //     },
    //   ];
    //   const jsonData = JSON.stringify(password);
    //   localStorage.setItem("storedData", jsonData);
    // }
    const schema = Yup.object().shape({
      phone: Yup.string().required("OTP is required").min(6, "OTP must be at least 6 characters").max(6, "OTP just only 6 characters"),
      // password: Yup.string()

      //   .required("Password is required"),
    });
    const onSubmit = (values) => {
      // localStorage.setItem("phone", values.phone);


      ShowLoading();

      let formData = new FormData();

      formData.append("phone", Cookies.get('useremailphone'));
      formData.append("otp", values.phone);
      formData.append("token", Cookies.get('token'));

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      let api = "";
      if (process.env.NODE_ENV === "development") {
        api = base_url + 'confirm_otp';
      } else {
        api = base_url + 'confirm_otp';
      }

      axios
        .post(api, formData, { timeout: timeout })
        .then((response) => {

          if (response.data.status == true) {
            Cookies.set("token_refresh", response.data.token);
            Cookies.set("token", "");
            CloseLoading();
            goPage('changepassword');

          } else {
            // this.showAlert(response.data.message);
            // this.email = "0" + this.email.substring(2);
            // this.isi_button = `<div class="fs-7">Login</div>`;
            AlertPopup("error", "", response.data.message, 2000, false);
          }
        })
        .catch((error) => {
          // this.isi_button = `<div class="fs-7">Login</div>`;

          ErrorConnectionTimeOut(error);
        });

    };
    return {
      schema,
      onSubmit,
    };
  },
};
</script>
